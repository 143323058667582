import React from 'react';
import { Card, CardContent } from './ui/card';
import { InputField } from './ui/input';
import { Select } from './ui/select';

const FinancialParameters = ({ params, handleInputChange }) => {
  const terminalValueOptions = [
    { value: 'perpetuityGrowth', label: 'Perpetuity Growth' },
    { value: 'exitMultipleRevenue', label: 'Annual Revenue Multiple' },
    { value: 'exitMultipleEVTIA', label: ' Annual Invested Amount Multiple' },
  ];

  const handleTerminalValueMethodChange = (event) => {
    const newValue = event && event.target ? event.target.value : 'perpetuityGrowth';
    handleInputChange({
      target: {
        name: 'terminalValueMethod',
        value: newValue
      }
    });
  };

  const renderMultipleInput = () => {
    if (params.terminalValueMethod === 'exitMultipleRevenue') {
      return (
        <InputField
          label="Annual Revenue Multiple"
          name="revenueMultiple"
          value={params.revenueMultiple || ''}
          onChange={handleInputChange}
          step={0.1}
          className="mt-4"
        />
      );
    } else if (params.terminalValueMethod === 'exitMultipleEVTIA') {
      return (
        <InputField
          label="Annual Invested Amount Multiple"
          name="evtiaMultiple"
          value={params.evtiaMultiple || ''}
          onChange={handleInputChange}
          step={0.01}
          className="mt-4"
        />
      );
    }
    return null;
  };

  return (
    <Card className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm">
      <CardContent className="p-4">
        <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">Financial Parameters</h2>
        <div className="space-y-4">
          <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-200">Investor Scenario</h3>
          <div>
            <InputField
              label="Investor's Initial Investment"
              type="number"
              id="initialInvestment"
              name="initialInvestment"
              value={params.initialInvestment || ''}
              onChange={handleInputChange}
              className="mt-1"
            />
          </div>
          <div>
            <InputField
              label="Investor's Equity Percentage (%)"
              type="number"
              id="equityPercentage"
              name="equityPercentage"
              value={params.equityPercentage || ''}
              onChange={handleInputChange}
              className="mt-1"
            />
          </div>
          <h3 className="text-lg font-semibold mb-2 mt-6 text-gray-800 dark:text-gray-200">General Financial Parameters</h3>
          <div>
            <InputField
              label="Discount Rate (%)"
              type="number"
              id="discountRate"
              name="discountRate"
              value={params.discountRate || ''}
              onChange={handleInputChange}
              className="mt-1"
            />
          </div>
          <div>
            <Select
              label="Terminal Value Calculation Method"
              id="terminalValueMethod"
              name="terminalValueMethod"
              value={params.terminalValueMethod || 'perpetuityGrowth'}
              onChange={handleTerminalValueMethodChange}
              options={terminalValueOptions}
              className="mt-1"
            />
          </div>

          {renderMultipleInput()}

          {params.terminalValueMethod === 'perpetuityGrowth' && (
            <div>
              <InputField
                label="Perpetuity Growth Rate (%)"
                type="number"
                id="terminalGrowthRate"
                name="terminalGrowthRate"
                value={params.terminalGrowthRate || ''}
                onChange={handleInputChange}
                className="mt-1"
              />
            </div>
          )}

          <div>
            <InputField
              label="Income Tax Rate (%)"
              type="number"
              id="incomeTaxRate"
              name="incomeTaxRate"
              value={params.incomeTaxRate || ''}
              onChange={handleInputChange}
              className="mt-1"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default FinancialParameters;