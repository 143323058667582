import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { InputField } from './ui/input';

const YearlyParameters = ({ params, handleInputChange, selectedYear }) => {
    return (
        <div className="mb-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                <Card className="flex flex-col h-full">
                    <CardHeader>
                        <CardTitle>Year {selectedYear} Investment Leader Params</CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4 flex-grow">
                        <InputField
                            label="Investment Leader Growth Rate (%)"
                            name={`yearlyGrowthRates.year${selectedYear}`}
                            value={params.yearlyGrowthRates[`year${selectedYear}`]}
                            onChange={handleInputChange}
                            step={0.1}
                        />
                        <InputField
                            label="Investment Leader Re-crowdfunding Rate (%)"
                            name={`yearlyReCrowdfundingRates.year${selectedYear}`}
                            value={params.yearlyReCrowdfundingRates[`year${selectedYear}`]}
                            onChange={handleInputChange}
                            step={0.1}
                        />
                        <InputField
                            label="Average Crowdfunding Amount per Selection ($)"
                            name={`yearlyCrowdfundingAmounts.year${selectedYear}`}
                            value={params.yearlyCrowdfundingAmounts[`year${selectedYear}`]}
                            onChange={handleInputChange}
                            step={1000}
                        />
                    </CardContent>
                </Card>
                <Card className="flex flex-col h-full">
                    <CardHeader>
                        <CardTitle>Year {selectedYear} Startups Params</CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4 flex-grow">
                        <InputField
                            label="Startup Refinancing Rate (%)"
                            name={`yearlyRefinancingRates.year${selectedYear}`}
                            value={params.yearlyRefinancingRates[`year${selectedYear}`]}
                            onChange={handleInputChange}
                            step={0.1}
                        />
                        <InputField
                            label="Average Refinancing Amount per Startup ($)"
                            name={`yearlyRefinancingAmounts.year${selectedYear}`}
                            value={params.yearlyRefinancingAmounts[`year${selectedYear}`]}
                            onChange={handleInputChange}
                            step={1000}
                        />
                    </CardContent>
                </Card>
                <Card className="flex flex-col h-full">
                    <CardHeader>
                        <CardTitle>Year {selectedYear} Secondary Market Params</CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4 flex-grow">
                        <InputField
                            label="Average Secondary Market Amount per Startup ($)"
                            name={`yearlyAverageSecondaryMarketAmount.year${selectedYear}`}
                            value={params.yearlyAverageSecondaryMarketAmount[`year${selectedYear}`]}
                            onChange={handleInputChange}
                            step={1000}
                        />
                    </CardContent>
                </Card>
                <Card className="flex flex-col h-full">
                    <CardHeader>
                        <CardTitle>Year {selectedYear} Retailers Params</CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4 flex-grow">
                        <InputField
                            label="Retailer Average Investment ($)"
                            name={`yearlyRetailerAverageInvestment.year${selectedYear}`}
                            value={params.yearlyRetailerAverageInvestment[`year${selectedYear}`]}
                            onChange={handleInputChange}
                            step={10}
                        />
                        <InputField
                            label="Retailer Reinvestment Rate (%)"
                            name={`yearlyRetailerReinvestmentRates.year${selectedYear}`}
                            value={params.yearlyRetailerReinvestmentRates[`year${selectedYear}`]}
                            onChange={handleInputChange}
                            step={0.1}
                        />
                    </CardContent>
                </Card>
            </div>
            {/* The Expenses card will be placed here by the parent component */}
        </div>
    );
};

export default YearlyParameters;