import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { formatCurrency } from '../utils';

const RevenueComparisonChart = ({ projections }) => {
  const data = projections.map(projection => ({
    year: projection.year,
    'Initial Crowdfunding': projection.revenue - projection.refinancingRevenue - projection.secondaryMarketFee,
    'Refinancing': projection.refinancingRevenue,
    'Secondary Market': projection.secondaryMarketFee,
  }));

  return (
    <Card className="mb-8">
      <CardHeader>
        <CardTitle className="text-xl font-bold">Revenue Comparison: Initial Crowdfunding vs Refinancing vs Secondary Market</CardTitle>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis tickFormatter={(value) => `$${value / 1000000}M`} />
            <Tooltip formatter={(value) => formatCurrency(value)} />
            <Legend />
            <Bar dataKey="Initial Crowdfunding" stackId="a" fill="#8884d8" />
            <Bar dataKey="Refinancing" stackId="a" fill="#82ca9d" />
            <Bar dataKey="Secondary Market" stackId="a" fill="#ffc658" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default RevenueComparisonChart;