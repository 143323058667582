import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { InputField } from './ui/input';

const ExpenseParameters = ({ params, handleInputChange, selectedYear }) => {
    const fixedExpenseCategories = [
        { name: 'salariesAndWages', label: 'Salaries and Wages' },
        { name: 'hostingFees', label: 'Hosting Fees' },
        { name: 'officeRentAndUtilities', label: 'Office Rent and Utilities' },
        { name: 'legalAndAdminFees', label: 'Legal & Administrative Fees' },
        { name: 'insurance', label: 'Insurance' },
    ];

    const percentageExpenseCategories = [
        { name: 'softwareDevAndRD', label: 'Software Dev and R&D' },
        { name: 'investmentLeaderAcquisition', label: 'Investment Leader Acquisition & Retention' },
        { name: 'steerupMarketing', label: 'SteerUp Marketing' },
        { name: 'startupSelectionTargeting', label: 'Startup Selections Retailers Targeting' },
    ];

    return (
        <Card className="col-span-full mb-6">
            <CardHeader>
                <CardTitle className="text-xl font-bold">Year {selectedYear} Expenses</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="space-y-4">
                    <h3 className="font-semibold">Fixed Annual Fees</h3>
                    {fixedExpenseCategories.map((category) => (
                        <InputField
                            key={category.name}
                            label={`${category.label} ($)`}
                            name={`yearlyExpenses.year${selectedYear}.${category.name}`}
                            value={params.yearlyExpenses[`year${selectedYear}`][category.name]}
                            onChange={handleInputChange}
                            step={1000}
                        />
                    ))}

                    <h3 className="font-semibold mt-6">Target Operating Income</h3>
                    <InputField
                        label="Target Operating Income (%)"
                        name={`yearlyExpenses.year${selectedYear}.reservedCashFlow`}
                        value={params.yearlyExpenses[`year${selectedYear}`].reservedCashFlow || 0}
                        onChange={handleInputChange}
                        step={0.1}
                    />

                    <h3 className="font-semibold mt-6">Percentage of Remaining Cash Flow</h3>
                    {percentageExpenseCategories.map((category) => (
                        <InputField
                            key={category.name}
                            label={`${category.label} (%)`}
                            name={`yearlyExpenses.year${selectedYear}.${category.name}`}
                            value={params.yearlyExpenses[`year${selectedYear}`][category.name]}
                            onChange={handleInputChange}
                            step={0.1}
                        />
                    ))}
                </div>
            </CardContent>
        </Card>
    );
};

export default ExpenseParameters;