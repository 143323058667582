import { baseParams, conservativeParams, optimisticParams } from './mockData';

export const getScenarioParams = (scenario) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            switch (scenario) {
                case 'conservative':
                    resolve(conservativeParams);
                    break;
                case 'optimistic':
                    resolve(optimisticParams);
                    break;
                default:
                    resolve(baseParams);
            }
        }, 10); // Simulate network delay
    });
};

export const importParams = (jsonString) => {
    return new Promise((resolve, reject) => {
        try {
            const importedData = JSON.parse(jsonString);

            // Validate that the imported data has all scenario keys
            const requiredScenarios = ['base', 'conservative', 'optimistic'];
            const missingScenarios = requiredScenarios.filter(scenario => !(scenario in importedData));

            if (missingScenarios.length > 0) {
                throw new Error(`Missing scenarios: ${missingScenarios.join(', ')}`);
            }

            // Validate each scenario's parameters
            Object.keys(importedData).forEach(scenario => {
                validateScenarioParams(importedData[scenario]);
            });

            resolve(importedData);
        } catch (error) {
            console.error('Error parsing or validating imported parameters:', error);
            reject(new Error(`Invalid JSON format or missing required parameters: ${error.message}`));
        }
    });
};

export const exportParams = (params) => {
    return new Promise((resolve) => {
        const exportData = {
            base: params.base,
            conservative: params.conservative,
            optimistic: params.optimistic
        };
        const jsonString = JSON.stringify(exportData, null, 2);
        resolve(jsonString);
    });
};

function validateScenarioParams(params) {
    const requiredKeys = [
        'serviceableObtainableMarket',
        'initialInvestmentLeaders',
        'yearlyRetailerAverageInvestment',
        'yearlyGrowthRates',
        'yearlyReCrowdfundingRates',
        'yearlyRetailerReinvestmentRates',
        'yearlyCrowdfundingAmounts',
        'discountRate',
        'yearlyExpenses',
        'incomeTaxRate'
    ];

    const missingKeys = requiredKeys.filter(key => !(key in params));

    if (missingKeys.length > 0) {
        throw new Error(`Missing required parameters: ${missingKeys.join(', ')}`);
    }

    // Ensure yearly parameters have entries for all 10 years
    const yearlyParams = [
        'yearlyRetailerAverageInvestment',
        'yearlyGrowthRates',
        'yearlyReCrowdfundingRates',
        'yearlyRetailerReinvestmentRates',
        'yearlyCrowdfundingAmounts',
        'yearlyRefinancingRates',
        'yearlyRefinancingAmounts',
        'yearlyAverageSecondaryMarketAmount'
    ];

    yearlyParams.forEach(paramName => {
        if (!(paramName in params)) {
            params[paramName] = {};
        }
        for (let i = 1; i <= 10; i++) {
            if (!(`year${i}` in params[paramName])) {
                params[paramName][`year${i}`] = 0;
            }
        }
    });

    // Ensure yearlyExpenses exists and has entries for all 10 years
    if (!params.yearlyExpenses) {
        params.yearlyExpenses = {};
    }
    for (let i = 1; i <= 10; i++) {
        if (!params.yearlyExpenses[`year${i}`]) {
            params.yearlyExpenses[`year${i}`] = {
                salariesAndWages: 0,
                hostingFees: 0,
                officeRentAndUtilities: 0,
                legalAndAdminFees: 0,
                insurance: 0,
                reservedCashFlow: 0,
                softwareDevAndRD: 0,
                investmentLeaderAcquisition: 0,
                steerupMarketing: 0,
                startupSelectionTargeting: 0
            };
        }
    }
}