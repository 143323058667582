import React from 'react';

export const Card = ({ children, className, ...props }) => (
    <div className={`bg-white dark:bg-gray-800 shadow rounded-lg p-4 ${className}`} {...props}>
        {children}
    </div>
);

export const CardHeader = ({ children, className, ...props }) => (
    <div className={`mb-4 ${className}`} {...props}>
        {children}
    </div>
);

export const CardTitle = ({ children, className, ...props }) => (
    <h2 className={`text-xl font-bold text-gray-900 dark:text-white ${className}`} {...props}>
        {children}
    </h2>
);

export const CardContent = ({ children, className, ...props }) => (
    <div className={`text-gray-700 dark:text-gray-300 ${className}`} {...props}>
        {children}
    </div>
);