import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { InputField } from './ui/input';

const SelectionParameters = ({ params, handleInputChange, selectedYear }) => {
    return (
        <Card className="mb-6">
            <CardHeader>
                <CardTitle className="text-xl font-bold">Selection Parameters</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
                <InputField
                    label="Startups Crowdfunding Fee (%)"
                    name="startupsCrowdfundingFee"
                    value={params.startupsCrowdfundingFee}
                    onChange={handleInputChange}
                    step={0.1}
                />
                <InputField
                    label="Lead Investor Platform Fee (%)"
                    name="leadInvestorPlatformFee"
                    value={params.leadInvestorPlatformFee}
                    onChange={handleInputChange}
                    step={0.1}
                />
                <InputField
                    label="Retailers Membership Fee (%)"
                    name="retailersMembershipFee"
                    value={params.retailersMembershipFee}
                    onChange={handleInputChange}
                    step={0.1}
                />
                <InputField
                    label="Refinancing Fee (%)"
                    name="refinancingFee"
                    value={params.refinancingFee}
                    onChange={handleInputChange}
                    step={0.1}
                />
                <InputField
                    label="Secondary Market Fee (%)"
                    name="secondaryMarketFee"
                    value={params.secondaryMarketFee}
                    onChange={handleInputChange}
                    step={0.1}
                />
                <InputField
                    label="Average Startups per Selection"
                    name="averageStartupsPerSelection"
                    value={params.averageStartupsPerSelection}
                    onChange={handleInputChange}
                    step={1}
                    type="number"
                />
            </CardContent>
        </Card>
    );
};

export default SelectionParameters;