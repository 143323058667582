import React from 'react';
import { Card, CardContent } from './ui/card';
import { formatCurrency, formatNumber, formatPercentage } from '../utils';

const ReturnCard = ({ title, value, note }) => (
    <Card className="bg-blue-50 dark:bg-blue-900 border border-blue-200 dark:border-blue-700 rounded-lg shadow-sm">
        <CardContent className="p-2">
            <h3 className="text-blue-500 dark:text-blue-300 text-lg font-semibold mb-1">{title}</h3>
            <p className="text-gray-800 dark:text-gray-100 text-2xl font-bold">{value}</p>
            {note && <p className="text-gray-600 dark:text-gray-400 text-sm mt-1">{note}</p>}
        </CardContent>
    </Card>
);

const InvestorReturns = ({ exitValue, irr, moic }) => {
    return (
        <div className="space-y-4">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Investor Scenario Returns</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
                <ReturnCard
                    title="Investor's Exit Value"
                    value={formatCurrency(exitValue)}
                    note="Projected value after 10 years"
                />
                <ReturnCard
                    title="Investor's IRR"
                    value={irr !== null ? formatPercentage(irr * 100) : 'N/A'}
                    note="Typical minimum IRR for pre-seed fintech: 40-50%"
                />
                <ReturnCard
                    title="Investor's MOIC"
                    value={moic !== null ? formatNumber(moic, 2) + 'x' : 'N/A'}
                    note="Typical minimum MOIC for pre-seed fintech: 10x-15x"
                />
            </div>
        </div>
    );
};

export default InvestorReturns;