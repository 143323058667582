import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { InputField } from './ui/input';

const MarketParameters = ({ params, handleInputChange }) => {
    return (
        <Card className="mb-6">
            <CardHeader>
                <CardTitle className="text-xl font-bold">Market Parameters</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
                <InputField
                    label="Serviceable Obtainable Market ($)"
                    name="serviceableObtainableMarket"
                    value={params.serviceableObtainableMarket}
                    onChange={handleInputChange}
                    step={1000000}
                />
                <InputField
                    label="Initial Number of Investment Leaders"
                    name="initialInvestmentLeaders"
                    value={params.initialInvestmentLeaders}
                    onChange={handleInputChange}
                    step={1}
                />
            </CardContent>
        </Card>
    );
};

export default MarketParameters;