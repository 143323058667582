import React, { useState, useEffect, useCallback, useMemo } from 'react';
import YearSelector from './components/YearSelector';
import MarketParameters from './components/MarketParameters';
import YearlyParameters from './components/YearlyParameters';
import FinancialParameters from './components/FinancialParameters';
import ExpenseParameters from './components/ExpensesParameters';
import SelectionParameters from './components/SelectionParameters';
import ValuationSummary from './components/ValuationSummary';
import InvestorReturns from './components/InvestorReturns';
import ProjectionChart from './components/ProjectionChart';
import ProjectionTable from './components/ProjectionTable';
import RevenueComparisonChart from './components/RevenueComparisonChart';
import ScenarioSelector from './components/ScenarioSelector';
import { calculateProjections, calculatePresentValue, calculateIRR, calculateMOIC, calculateTerminalValue } from './calculations';
import { getScenarioParams, importParams, exportParams } from './services/mockApi';

const App = () => {
  const [selectedYear, setSelectedYear] = useState(1);
  const [selectedScenario, setSelectedScenario] = useState('base');
  const [scenarioParams, setScenarioParams] = useState({
    base: null,
    conservative: null,
    optimistic: null
  });
  const [projections, setProjections] = useState([]);
  const [presentValue, setPresentValue] = useState(0);
  const [irr, setIRR] = useState(null);
  const [moic, setMOIC] = useState(null);
  const [exitValue, setExitValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchScenarioParams = async () => {
      setIsLoading(true);
      try {
        const baseParams = await getScenarioParams('base');
        const conservativeParams = await getScenarioParams('conservative');
        const optimisticParams = await getScenarioParams('optimistic');
        setScenarioParams({
          base: baseParams,
          conservative: conservativeParams,
          optimistic: optimisticParams
        });
        setError(null);
      } catch (error) {
        console.error('Error fetching scenario parameters:', error);
        setError('Failed to load scenario parameters. Please try refreshing the page.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchScenarioParams();
  }, []);

  const params = scenarioParams[selectedScenario];

  const memoizedCalculateProjections = useCallback(
    (params) => calculateProjections(params),
    []
  );

  useEffect(() => {
    if (params) {
      try {
        const newProjections = memoizedCalculateProjections(params);
        setProjections(newProjections);
        setError(null);
      } catch (error) {
        console.error('Error calculating projections:', error);
        setError('Failed to calculate projections. Please check your input parameters.');
      }
    }
  }, [params, memoizedCalculateProjections]);

  const memoizedPresentValue = useMemo(() => {
    if (projections.length > 0 && params) {
      return calculatePresentValue(projections, params);
    }
    return 0;
  }, [projections, params]);

  useEffect(() => {
    setPresentValue(memoizedPresentValue);
  }, [memoizedPresentValue]);

  useEffect(() => {
    if (projections.length > 0 && params && params.initialInvestment !== undefined && params.equityPercentage !== undefined) {
      const calculatedExitValue = calculateTerminalValue(projections, params);
      setExitValue(calculatedExitValue);
      const calculatedIRR = calculateIRR(params.initialInvestment, projections, params.equityPercentage, params);
      setIRR(calculatedIRR);
      const calculatedMOIC = calculateMOIC(params.initialInvestment, calculatedExitValue, params.equityPercentage);
      setMOIC(calculatedMOIC);
    } else {
      setIRR(null);
      setMOIC(null);
      setExitValue(null);
    }
  }, [projections, params]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setScenarioParams(prev => {
      const updatedScenario = { ...prev[selectedScenario] };
      const nameParts = name.split('.');

      if (nameParts.length === 1) {
        updatedScenario[name] = value === '' ? '' : parseFloat(value) || value;
      } else {
        let current = updatedScenario;
        for (let i = 0; i < nameParts.length - 1; i++) {
          if (!current[nameParts[i]]) {
            current[nameParts[i]] = {};
          }
          current = current[nameParts[i]];
        }
        current[nameParts[nameParts.length - 1]] = value === '' ? '' : parseFloat(value) || value;
      }

      return {
        ...prev,
        [selectedScenario]: updatedScenario
      };
    });
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  const handleScenarioChange = (scenario) => {
    setSelectedScenario(scenario);
  };

  const handleImport = async (file) => {
    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const importedParams = await importParams(event.target.result);
          setScenarioParams(importedParams);
          setError(null);
        } catch (error) {
          console.error('Error importing params:', error);
          setError('Failed to import parameters. Please check your file format.');
        }
      };
      reader.readAsText(file);
    } catch (error) {
      console.error('Error reading file:', error);
      setError('Failed to read the imported file.');
    }
  };

  const handleExport = async () => {
    try {
      const jsonString = await exportParams(scenarioParams);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'growth_projection_params_all_scenarios.json';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting params:', error);
      setError('Failed to export parameters.');
    }
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;
  }

  return (
    <div className={`max-w-7xl mx-auto p-4 space-y-6 dark`}>
      <div className="bg-white dark:bg-gray-900 min-h-screen">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            SteerUp Growth Projection Tool
          </h1>
          <div className="flex justify-between items-center">
            <ScenarioSelector
              selectedScenario={selectedScenario}
              onScenarioChange={handleScenarioChange}
            />
            <div className="flex items-center space-x-4">
              <button onClick={handleExport} className="bg-blue-500 text-white px-4 py-2 rounded">
                Export
              </button>
              <input
                type="file"
                accept=".json"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    handleImport(file);
                  }
                }}
                className="hidden"
                id="import-file"
              />
              <label htmlFor="import-file" className="bg-green-500 text-white px-4 py-2 rounded cursor-pointer">
                Import
              </label>
            </div>
          </div>
        </div>

        {projections.length > 0 && (
          <>
            <ValuationSummary
              presentValue={presentValue}
              projections={projections}
            />
            <div className="mt-4 mb-8">
              <InvestorReturns
                exitValue={exitValue}
                irr={irr}
                moic={moic}
              />
            </div>
          </>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          <MarketParameters params={params} handleInputChange={handleInputChange} />
          <SelectionParameters
            params={params}
            handleInputChange={handleInputChange}
            selectedYear={selectedYear}
          />
          <FinancialParameters params={params} handleInputChange={handleInputChange} />
        </div>

        <YearSelector selectedYear={selectedYear} onYearChange={handleYearChange} />

        <YearlyParameters
          params={params}
          handleInputChange={handleInputChange}
          selectedYear={selectedYear}
        />
        <ExpenseParameters params={params} handleInputChange={handleInputChange} selectedYear={selectedYear} />

        {projections.length > 0 && (
          <>
            <RevenueComparisonChart projections={projections} />
            <ProjectionChart projections={projections} />
            <ProjectionTable projections={projections} />
          </>
        )}
      </div>
    </div>
  );
};

export default App;