import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { formatCurrency, formatNumber, formatPercentage } from '../utils';

const ProjectionTable = ({ projections }) => {
    return (
        <Card className="mb-8">
            <CardHeader>
                <CardTitle className="text-xl font-bold">Projection Data</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th className="px-6 py-3">Year</th>
                                <th className="px-6 py-3">Investment Leaders</th>
                                <th className="px-6 py-3">New Selections</th>
                                <th className="px-6 py-3">Re-crowdfunding Selections</th>
                                <th className="px-6 py-3">Total Selections</th>
                                <th className="px-6 py-3">Required Retailers</th>
                                <th className="px-6 py-3">Actual Investment</th>
                                <th className="px-6 py-3">% of SOM</th>
                                <th className="px-6 py-3">Revenue</th>
                                <th className="px-6 py-3">Refinancing Revenue</th>
                                <th className="px-6 py-3">Fixed Expenses</th>
                                <th className="px-6 py-3">Investment Leader Acquisition</th>
                                <th className="px-6 py-3">SteerUp Marketing</th>
                                <th className="px-6 py-3">Software Dev & R&D</th>
                                <th className="px-6 py-3">Startup Selection Targeting</th>
                                <th className="px-6 py-3">Operating Income</th>
                                <th className="px-6 py-3">Operating Income %</th>
                                <th className="px-6 py-3">FCF</th>
                            </tr>
                        </thead>
                        <tbody>
                            {projections.map((data, index) => (
                                <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <td className="px-6 py-4">{data.year}</td>
                                    <td className="px-6 py-4">{formatNumber(data.investmentLeaders)}</td>
                                    <td className="px-6 py-4">{formatNumber(data.newLeaders)}</td>
                                    <td className="px-6 py-4">{formatNumber(data.reCrowdfundingLeaders)}</td>
                                    <td className="px-6 py-4">{formatNumber(data.totalSelections)}</td>
                                    <td className="px-6 py-4">{formatNumber(data.requiredRetailers)}</td>
                                    <td className="px-6 py-4">{formatCurrency(data.actualInvestment)}</td>
                                    <td className="px-6 py-4">{formatPercentage(data.percentageOfSOM)}</td>
                                    <td className="px-6 py-4">{formatCurrency(data.revenue)}</td>
                                    <td className="px-6 py-4">{formatCurrency(data.refinancingRevenue)}</td>
                                    <td className="px-6 py-4">{formatCurrency(data.fixedExpenses)}</td>
                                    <td className="px-6 py-4">{formatCurrency(data.investmentLeaderAcquisition)}</td>
                                    <td className="px-6 py-4">{formatCurrency(data.steerupMarketing)}</td>
                                    <td className="px-6 py-4">{formatCurrency(data.softwareDevAndRD)}</td>
                                    <td className="px-6 py-4">{formatCurrency(data.startupSelectionTargeting)}</td>
                                    <td className="px-6 py-4">{formatCurrency(data.operatingIncome)}</td>
                                    <td className="px-6 py-4">{formatPercentage(data.operatingIncomePercentage)}</td>
                                    <td className="px-6 py-4">{formatCurrency(data.fcf)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </Card>
    );
};

export default ProjectionTable;