import React from 'react';

const YearSelector = ({ selectedYear, onYearChange }) => {
    return (
        <div className="w-full max-w-3xl mx-auto mb-8">
            <h2 className="text-xl font-bold mb-4 text-center">Year {selectedYear} Parameters</h2>
            <input
                type="range"
                min="1"
                max="10"
                value={selectedYear}
                onChange={(e) => onYearChange(parseInt(e.target.value))}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            />
            <div className="flex justify-between mt-2 text-sm text-gray-600 dark:text-gray-400">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(year => (
                    <span key={year}>{year}</span>
                ))}
            </div>
        </div>
    );
};

export default YearSelector;