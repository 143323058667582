import React from 'react';
import { Card, CardContent } from './ui/card';
import { formatCurrency, formatNumber, formatPercentage } from '../utils';

const ValuationCard = ({ title, value, note }) => (
    <Card className="bg-blue-50 dark:bg-blue-900 border border-blue-200 dark:border-blue-700 rounded-lg shadow-sm">
        <CardContent className="p-2">
            <h3 className="text-blue-500 dark:text-blue-300 text-lg font-semibold mb-1">{title}</h3>
            <p className="text-gray-800 dark:text-gray-100 text-2xl font-bold">{value}</p>
            {note && <p className="text-gray-600 dark:text-gray-400 text-sm mt-1">{note}</p>}
        </CardContent>
    </Card>
);

const ValuationSummary = ({ presentValue, projections }) => {
    const lastYear = projections && projections.length > 0 ? projections[projections.length - 1] : null;

    return (
        <div className="space-y-4">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Valuation Summary</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
                <ValuationCard
                    title="SteerUp Present Value"
                    value={formatCurrency(presentValue)}
                />
                <ValuationCard
                    title="Year 10 SOM Penetration"
                    value={lastYear ? formatPercentage(lastYear.percentageOfSOM) : 'N/A'}
                />
                <ValuationCard
                    title="Year 10 Investment Leaders"
                    value={lastYear ? formatNumber(lastYear.investmentLeaders) : 'N/A'}
                />
                <ValuationCard
                    title="Year 10 New Selections"
                    value={lastYear ? formatNumber(lastYear.totalSelections) : 'N/A'}
                />
                <ValuationCard
                    title="Year 10 Required Retailers"
                    value={lastYear ? formatNumber(lastYear.requiredRetailers) : 'N/A'}
                />
                <ValuationCard
                    title="Year 10 Retailers Total Investment"
                    value={lastYear ? formatCurrency(lastYear.actualInvestment) : 'N/A'}
                />
            </div>
        </div>
    );
};

export default ValuationSummary;