import React from 'react';
import { Label } from './label';

export const InputField = ({ label, name, value, onChange, step = 1, className = '' }) => (
    <div className={`${className} space-y-2`}>
        <Label htmlFor={name} className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
        </Label>
        <input
            id={name}
            name={name}
            type="number"
            value={value}
            onChange={onChange}
            step={step}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm
                 dark:bg-gray-700 dark:border-gray-600 dark:text-white
                 px-3 py-2" // Added padding here
        />
    </div>
);