// utils.js
export const formatCurrency = (value) => {
  const absValue = Math.abs(value);
  if (absValue >= 1e9) {
    return `${value < 0 ? '-' : ''}$${(absValue / 1e9).toFixed(1)}B`;
  } else if (absValue >= 1e6) {
    return `${value < 0 ? '-' : ''}$${(absValue / 1e6).toFixed(1)}M`;
  } else if (absValue >= 1e3) {
    return `${value < 0 ? '-' : ''}$${(absValue / 1e3).toFixed(1)}K`;
  } else {
    return `${value < 0 ? '-' : ''}$${absValue.toFixed(0)}`;
  }
};

export const formatNumber = (value) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

export const formatPercentage = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value / 100);
};