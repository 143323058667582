export const calculateProjections = (params) => {
  const safeParams = getSafeParams(params);
  let investmentLeaders = safeParams.initialInvestmentLeaders;
  const projections = [];

  for (let year = 1; year <= 10; year++) {
    const growthRate = safeParams.yearlyGrowthRates[`year${year}`] || 0;
    const reCrowdfundingRate = safeParams.yearlyReCrowdfundingRates[`year${year}`] || 0;
    const retailerReinvestmentRate = safeParams.yearlyRetailerReinvestmentRates[`year${year}`] || 0;
    const refinancingRate = safeParams.yearlyRefinancingRates[`year${year}`] || 0;
    const retailerAverageInvestment = safeParams.yearlyRetailerAverageInvestment[`year${year}`] || 0;
    const averageSecondaryMarketAmount = safeParams.yearlyAverageSecondaryMarketAmount[`year${year}`] || 0;

    // Calculate new and re-crowdfunding investment leaders
    const newLeaders = Math.floor(investmentLeaders * (growthRate / 100));
    const reCrowdfundingLeaders = Math.floor(investmentLeaders * (reCrowdfundingRate / 100));
    const totalSelections = newLeaders + reCrowdfundingLeaders;

    // Calculate required investment amount
    const averageCrowdfundingAmount = safeParams.yearlyCrowdfundingAmounts[`year${year}`] || 0;
    const requiredCrowdfundingInvestment = totalSelections * averageCrowdfundingAmount;

    // Calculate refinancing investment and secondary market
    let refinancingInvestment = 0;
    let refinancingStartups = 0;
    let secondaryMarketVolume = 0;
    if (year > 1) {
      const averageStartupsPerSelection = safeParams.averageStartupsPerSelection || 3;
      refinancingStartups = Math.floor(projections[year - 2].totalSelections * averageStartupsPerSelection * (refinancingRate / 100));
      const averageRefinancingAmount = safeParams.yearlyRefinancingAmounts[`year${year}`] || 0;
      refinancingInvestment = refinancingStartups * averageRefinancingAmount;
      secondaryMarketVolume = refinancingStartups * averageSecondaryMarketAmount;
    }

    // Calculate total required investment
    const totalRequiredInvestment = requiredCrowdfundingInvestment + refinancingInvestment;

    // Calculate required retailers
    let requiredRetailers;
    if (year === 1) {
      requiredRetailers = Math.ceil(totalRequiredInvestment / retailerAverageInvestment);
    } else {
      const reinvestingRetailers = Math.floor(projections[year - 2].requiredRetailers * (retailerReinvestmentRate / 100));
      const reinvestedAmount = reinvestingRetailers * retailerAverageInvestment;
      const remainingRequiredInvestment = Math.max(0, totalRequiredInvestment - reinvestedAmount);
      const newRetailers = Math.ceil(remainingRequiredInvestment / retailerAverageInvestment);
      requiredRetailers = reinvestingRetailers + newRetailers;
    }

    // Calculate actual investment
    const actualInvestment = requiredRetailers * retailerAverageInvestment;

    // Calculate revenue
    const startupsFee = requiredCrowdfundingInvestment * (safeParams.startupsCrowdfundingFee / 100);
    const leadInvestorFee = requiredCrowdfundingInvestment * (safeParams.leadInvestorPlatformFee / 100);
    const retailersFee = actualInvestment * (safeParams.retailersMembershipFee / 100);
    const refinancingRevenue = refinancingInvestment * (safeParams.refinancingFee / 100);
    const secondaryMarketFee = secondaryMarketVolume * (safeParams.secondaryMarketFee / 100);

    const revenue = startupsFee + leadInvestorFee + retailersFee + refinancingRevenue + secondaryMarketFee;

    // Calculate expenses
    const fixedExpenses = calculateFixedExpenses(safeParams.yearlyExpenses[`year${year}`]);
    const { percentageBasedExpenses, operatingIncome, operatingIncomePercentage } = calculatePercentageBasedExpenses(
      safeParams.yearlyExpenses[`year${year}`],
      revenue,
      fixedExpenses
    );

    // Calculate FCF
    const taxes = operatingIncome > 0 ? operatingIncome * (safeParams.incomeTaxRate / 100) : 0;
    const fcf = operatingIncome - taxes;

    projections.push({
      year,
      investmentLeaders,
      newLeaders,
      reCrowdfundingLeaders,
      totalSelections,
      requiredRetailers,
      actualInvestment,
      requiredCrowdfundingInvestment,
      refinancingInvestment,
      refinancingStartups,
      secondaryMarketVolume,
      secondaryMarketFee,
      totalRequiredInvestment,
      revenue,
      refinancingRevenue,
      operatingIncome,
      operatingIncomePercentage,
      fcf,
      percentageOfSOM: (actualInvestment / safeParams.serviceableObtainableMarket) * 100,
      fixedExpenses,
      ...percentageBasedExpenses,
    });

    // Update investment leaders for next year
    investmentLeaders += newLeaders;
  }

  return projections;
};

const getSafeParams = (params) => {
  return Object.fromEntries(
    Object.entries(params).map(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        return [key, getSafeParams(value)];
      }
      return [key, value === '' ? 0 : Number(value) || value];
    })
  );
};

const calculateFixedExpenses = (yearlyExpenses) => {
  return ['salariesAndWages', 'hostingFees', 'officeRentAndUtilities', 'legalAndAdminFees', 'insurance']
    .reduce((sum, expenseKey) => sum + (yearlyExpenses[expenseKey] || 0), 0);
};

const calculatePercentageBasedExpenses = (yearlyExpenses, revenue, fixedExpenses) => {
  const targetOperatingIncomePercentage = yearlyExpenses.reservedCashFlow || 0;
  const targetOperatingIncome = revenue * (targetOperatingIncomePercentage / 100);
  const availableCashFlow = Math.max(0, revenue - fixedExpenses - targetOperatingIncome);

  const totalPercentage = ['softwareDevAndRD', 'investmentLeaderAcquisition', 'steerupMarketing', 'startupSelectionTargeting']
    .reduce((sum, expenseKey) => sum + (yearlyExpenses[expenseKey] || 0), 0);

  const percentageBasedExpenses = ['softwareDevAndRD', 'investmentLeaderAcquisition', 'steerupMarketing', 'startupSelectionTargeting']
    .reduce((expenses, expenseKey) => {
      const percentage = yearlyExpenses[expenseKey] / totalPercentage;
      expenses[expenseKey] = availableCashFlow * percentage;
      return expenses;
    }, {});

  const totalExpenses = fixedExpenses + Object.values(percentageBasedExpenses).reduce((sum, expense) => sum + expense, 0);
  const actualOperatingIncome = revenue - totalExpenses;
  const actualOperatingIncomePercentage = (actualOperatingIncome / revenue) * 100;

  return {
    percentageBasedExpenses,
    operatingIncome: actualOperatingIncome,
    operatingIncomePercentage: actualOperatingIncomePercentage,
  };
};

export const calculateTerminalValue = (projections, params) => {
  const lastYear = projections[9];

  switch (params.terminalValueMethod) {
    case 'perpetuityGrowth':
      return lastYear.fcf * (1 + params.terminalGrowthRate / 100) / (params.discountRate / 100 - params.terminalGrowthRate / 100);

    case 'exitMultipleRevenue':
      return lastYear.revenue * params.revenueMultiple;

    case 'exitMultipleEVTIA':
      const totalInvestedAmount = projections.reduce((sum, year) => sum + year.totalRequiredInvestment, 0);
      return totalInvestedAmount * params.evtiaMultiple;

    default:
      console.warn(`Unexpected terminal value method: ${params.terminalValueMethod}. Using perpetuity growth method as default.`);
      return lastYear.fcf * (1 + params.terminalGrowthRate / 100) / (params.discountRate / 100 - params.terminalGrowthRate / 100);
  }
};

export const calculatePresentValue = (projections, params) => {
  let pv = 0;
  const terminalValue = calculateTerminalValue(projections, params);

  for (let i = 0; i < 10; i++) {
    pv += projections[i].fcf / Math.pow(1 + params.discountRate / 100, i + 1);
  }

  pv += terminalValue / Math.pow(1 + params.discountRate / 100, 10);

  return pv;
};

export const calculateIRR = (initialInvestment, projections, equityPercentage, params) => {
  const terminalValue = calculateTerminalValue(projections, params);
  const cashFlows = [
    -initialInvestment,
    ...projections.map(proj => proj.fcf * (equityPercentage / 100)),
    terminalValue * (equityPercentage / 100)
  ];

  const npv = (rate) => {
    return cashFlows.reduce((sum, cf, index) => sum + cf / Math.pow(1 + rate, index), 0);
  };

  const derivativeNpv = (rate) => {
    return cashFlows.reduce((sum, cf, index) => sum - index * cf / Math.pow(1 + rate, index + 1), 0);
  };

  let rate = 0.1; // Initial guess
  let maxIterations = 100;
  let tolerance = 0.0001;

  for (let i = 0; i < maxIterations; i++) {
    const npvValue = npv(rate);
    if (Math.abs(npvValue) < tolerance) {
      return rate;
    }
    rate = rate - npvValue / derivativeNpv(rate);
  }

  return null; // IRR not found within the maximum number of iterations
};

export const calculateMOIC = (initialInvestment, terminalValue, equityPercentage) => {
  return (terminalValue * (equityPercentage / 100)) / initialInvestment;
};