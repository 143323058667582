import React from 'react';
import { Label } from './label';

export const Select = ({ label, value, onChange, options, className = '' }) => (
    <div className={`${className} space-y-2`}>
        <Label htmlFor={label} className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
        </Label>
        <select
            id={label}
            value={value}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm
                 dark:bg-gray-700 dark:border-gray-600 dark:text-white
                 px-3 py-2"
        >
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    </div>
);