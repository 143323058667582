const scenarioParams = {
    initialInvestment: 100000,
    equityPercentage: 20,
    serviceableObtainableMarket: 500000000,
    initialInvestmentLeaders: 2,
    averageStartupsPerSelection: 3,
    yearlyRetailerAverageInvestment: {
        "year1": 650,
        "year2": 750,
        "year3": 1000,
        "year4": 1500,
        "year5": 2000,
        "year6": 2750,
        "year7": 3500,
        "year8": 4000,
        "year9": 4100,
        "year10": 4250
    },
    yearlyGrowthRates: {
        "year1": 80,
        "year2": 80,
        "year3": 75,
        "year4": 65,
        "year5": 65,
        "year6": 60,
        "year7": 50,
        "year8": 35,
        "year9": 30,
        "year10": 20
    },
    yearlyReCrowdfundingRates: {
        "year1": 50,
        "year2": 50,
        "year3": 35,
        "year4": 35,
        "year5": 35,
        "year6": 35,
        "year7": 30,
        "year8": 30,
        "year9": 30,
        "year10": 30
    },
    yearlyRetailerReinvestmentRates: {
        "year1": 15,
        "year2": 10,
        "year3": 15,
        "year4": 20,
        "year5": 25,
        "year6": 25,
        "year7": 25,
        "year8": 25,
        "year9": 30,
        "year10": 30
    },
    yearlyCrowdfundingAmounts: {
        "year1": 650000,
        "year2": 650000,
        "year3": 650000,
        "year4": 650000,
        "year5": 650000,
        "year6": 650000,
        "year7": 650000,
        "year8": 650000,
        "year9": 650000,
        "year10": 650000
    },
    yearlyRefinancingRates: {
        "year1": 0,
        "year2": 15,
        "year3": 20,
        "year4": 25,
        "year5": 30,
        "year6": 35,
        "year7": 35,
        "year8": 35,
        "year9": 40,
        "year10": 40
    },
    yearlyRefinancingAmounts: {
        "year1": 0,
        "year2": 1000000,
        "year3": 1500000,
        "year4": 2000000,
        "year5": 2500000,
        "year6": 2500000,
        "year7": 2500000,
        "year8": 3500000,
        "year9": 3500000,
        "year10": 4000000
    },
    yearlyAverageSecondaryMarketAmount: {
        "year1": 0,
        "year2": 100000,
        "year3": 150000,
        "year4": 350000,
        "year5": 500000,
        "year6": 550000,
        "year7": 700000,
        "year8": 800000,
        "year9": 1000000,
        "year10": 1100000
    },
    secondaryMarketFee: 3,
    terminalValueMethod: 'perpetuityGrowth',
    terminalGrowthRate: 2,
    revenueMultiple: 5,
    evtiaMultiple: 0.3,
    discountRate: 20,
    startupsCrowdfundingFee: 7,
    leadInvestorPlatformFee: 4,
    retailersMembershipFee: 3,
    refinancingFee: 5,
    yearlyExpenses: {
        year1: {
            salariesAndWages: 120000,
            hostingFees: 1000,
            officeRentAndUtilities: 0,
            legalAndAdminFees: 40000,
            insurance: 10000,
            reservedCashFlow: 0,
            softwareDevAndRD: 30,
            investmentLeaderAcquisition: 10,
            steerupMarketing: 15,
            startupSelectionTargeting: 45
        },
        year2: {
            salariesAndWages: 120000,
            hostingFees: 1000,
            officeRentAndUtilities: 0,
            legalAndAdminFees: 40000,
            insurance: 10000,
            reservedCashFlow: 0,
            softwareDevAndRD: 20,
            investmentLeaderAcquisition: 15,
            steerupMarketing: 30,
            startupSelectionTargeting: 35
        },
        year3: {
            salariesAndWages: 200000,
            hostingFees: 3000,
            officeRentAndUtilities: 30000,
            legalAndAdminFees: 85000,
            insurance: 20000,
            reservedCashFlow: 0,
            softwareDevAndRD: 20,
            investmentLeaderAcquisition: 15,
            steerupMarketing: 30,
            startupSelectionTargeting: 35
        },
        year4: {
            salariesAndWages: 250000,
            hostingFees: 3000,
            officeRentAndUtilities: 30000,
            legalAndAdminFees: 85000,
            insurance: 20000,
            reservedCashFlow: 5,
            softwareDevAndRD: 20,
            investmentLeaderAcquisition: 15,
            steerupMarketing: 30,
            startupSelectionTargeting: 35
        },
        year5: {
            salariesAndWages: 400000,
            hostingFees: 3000,
            officeRentAndUtilities: 30000,
            legalAndAdminFees: 85000,
            insurance: 20000,
            reservedCashFlow: 15,
            softwareDevAndRD: 20,
            investmentLeaderAcquisition: 15,
            steerupMarketing: 30,
            startupSelectionTargeting: 35
        },
        year6: {
            salariesAndWages: 400000,
            hostingFees: 3000,
            officeRentAndUtilities: 30000,
            legalAndAdminFees: 85000,
            insurance: 20000,
            reservedCashFlow: 25,
            softwareDevAndRD: 20,
            investmentLeaderAcquisition: 15,
            steerupMarketing: 30,
            startupSelectionTargeting: 35
        },
        year7: {
            salariesAndWages: 800000,
            hostingFees: 8000,
            officeRentAndUtilities: 70000,
            legalAndAdminFees: 200000,
            insurance: 40000,
            reservedCashFlow: 30,
            softwareDevAndRD: 20,
            investmentLeaderAcquisition: 15,
            steerupMarketing: 30,
            startupSelectionTargeting: 35
        },
        year8: {
            salariesAndWages: 800000,
            hostingFees: 8000,
            officeRentAndUtilities: 70000,
            legalAndAdminFees: 200000,
            insurance: 40000,
            reservedCashFlow: 35,
            softwareDevAndRD: 20,
            investmentLeaderAcquisition: 15,
            steerupMarketing: 30,
            startupSelectionTargeting: 35
        },
        year9: {
            salariesAndWages: 800000,
            hostingFees: 8000,
            officeRentAndUtilities: 70000,
            legalAndAdminFees: 200000,
            insurance: 40000,
            reservedCashFlow: 35,
            softwareDevAndRD: 20,
            investmentLeaderAcquisition: 15,
            steerupMarketing: 30,
            startupSelectionTargeting: 35
        },
        year10: {
            salariesAndWages: 800000,
            hostingFees: 8000,
            officeRentAndUtilities: 70000,
            legalAndAdminFees: 200000,
            insurance: 40000,
            reservedCashFlow: 35,
            softwareDevAndRD: 20,
            investmentLeaderAcquisition: 15,
            steerupMarketing: 30,
            startupSelectionTargeting: 35
        },
    },
    incomeTaxRate: 26.5,
};

export const baseParams = { ...scenarioParams };
export const conservativeParams = { ...scenarioParams };
export const optimisticParams = { ...scenarioParams };