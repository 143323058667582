import React from 'react';
import { Select } from './ui/select';

const ScenarioSelector = ({ selectedScenario, onScenarioChange }) => {
    const scenarioOptions = [
        { value: 'base', label: 'Base Scenario' },
        { value: 'conservative', label: 'Conservative Scenario' },
        { value: 'optimistic', label: 'Optimistic Scenario' },
    ];

    return (
        <div className="w-64">
            <Select
                label="Scenario"
                value={selectedScenario}
                onChange={(e) => onScenarioChange(e.target.value)}
                options={scenarioOptions}
            />
        </div>
    );
};

export default ScenarioSelector;