import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LabelList, LineChart, Line, ComposedChart } from 'recharts';
import { formatCurrency, formatNumber } from '../utils';

const ChartCard = ({ title, children }) => (
    <Card className="mb-8">
        <CardHeader>
            <CardTitle className="text-xl font-bold">{title}</CardTitle>
        </CardHeader>
        <CardContent className="h-96">{children}</CardContent>
    </Card>
);

const CustomizedLabel = ({ x, y, width, height, value, formatter }) => {
    const formattedValue = formatter(value);
    const yOffset = value >= 0 ? -6 : 15;

    return (
        <text x={x + width / 2} y={y + yOffset} fill="#666" textAnchor="middle" dominantBaseline="middle">
            {formattedValue}
        </text>
    );
};

const ProjectionChart = ({ projections }) => {
    return (
        <>
            <ChartCard title="Investment Leaders and Selections Growth">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={projections} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <XAxis dataKey="year" />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <Tooltip formatter={(value) => formatNumber(value)} />
                        <Legend />
                        <Line yAxisId="left" type="monotone" dataKey="investmentLeaders" name="Total Investment Leaders" stroke="#8884d8" />
                        <Line yAxisId="right" type="monotone" dataKey="totalSelections" name="Total Selections" stroke="#82ca9d" />
                    </LineChart>
                </ResponsiveContainer>
            </ChartCard>

            <ChartCard title="Required Retailers">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={projections} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <XAxis dataKey="year" />
                        <YAxis tickFormatter={formatNumber} />
                        <Tooltip formatter={(value) => formatNumber(value)} />
                        <Legend />
                        <Bar dataKey="requiredRetailers" fill="#ffc658" name="Required Retailers">
                            <LabelList content={<CustomizedLabel formatter={formatNumber} />} />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </ChartCard>

            <ChartCard title="New vs Re-crowdfunding Selections">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={projections} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <XAxis dataKey="year" />
                        <YAxis tickFormatter={formatNumber} />
                        <Tooltip formatter={(value) => formatNumber(value)} />
                        <Legend />
                        <Bar dataKey="newLeaders" stackId="a" fill="#8884d8" name="New Selections">
                            <LabelList content={<CustomizedLabel formatter={formatNumber} />} />
                        </Bar>
                        <Bar dataKey="reCrowdfundingLeaders" stackId="a" fill="#82ca9d" name="Re-crowdfunding Selections">
                            <LabelList content={<CustomizedLabel formatter={formatNumber} />} />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </ChartCard>

            <ChartCard title="Refinancing and Secondary Market">
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart data={projections} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <XAxis dataKey="year" />
                        <YAxis yAxisId="left" tickFormatter={formatNumber} />
                        <YAxis yAxisId="right" orientation="right" tickFormatter={formatCurrency} />
                        <Tooltip
                            formatter={(value, name) => {
                                if (name === "Refinancing Startups") return formatNumber(value);
                                return formatCurrency(value);
                            }}
                        />
                        <Legend />
                        <Bar yAxisId="left" dataKey="refinancingStartups" fill="#8884d8" name="Refinancing Startups">
                            <LabelList content={<CustomizedLabel formatter={formatNumber} />} />
                        </Bar>
                        <Line yAxisId="right" type="monotone" dataKey="secondaryMarketVolume" stroke="#82ca9d" name="Secondary Market Volume" />
                        <Line yAxisId="right" type="monotone" dataKey="secondaryMarketFee" stroke="#ffc658" name="Secondary Market Fee" />
                    </ComposedChart>
                </ResponsiveContainer>
            </ChartCard>

            <ChartCard title="Free Cash Flow">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={projections} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <XAxis dataKey="year" />
                        <YAxis tickFormatter={formatCurrency} />
                        <Tooltip formatter={(value) => formatCurrency(value)} />
                        <Legend />
                        <Bar dataKey="fcf" fill="#8884d8" name="Free Cash Flow">
                            <LabelList content={<CustomizedLabel formatter={formatCurrency} />} />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </ChartCard>
        </>
    );
};

export default ProjectionChart;